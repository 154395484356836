import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Box, Grid } from '@mui/material';


const NewsletterSubscription = () => {
  const [email, setEmail] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add subscription logic
    console.log(email);
  };

  return (
    <Box sx={{ my: 4, py: 4, backgroundColor: '#f1f1f1', borderRadius: '8px' }}>
      <Container>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Subscribe to our Newsletter
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Stay updated with the latest offers and discounts from Paphos Pass.
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={8} md={6}>
              <TextField
                label="Email"
                value={email}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                Subscribe
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default NewsletterSubscription;

