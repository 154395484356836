import React from 'react';
import { Typography, Grid, Box, Card, CardContent, Container, Fade } from '@mui/material';
import { styled } from '@mui/system';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

const FeatureCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
}));

const StepCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(3),
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    right: '-30px',
    width: '30px',
    height: '2px',
    backgroundColor: theme.palette.primary.main,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  '&:last-child::before': {
    display: 'none',
  },
}));

const AboutPaphosPass = () => {
  const features = [
    { icon: <LocalBarIcon fontSize="large" color="primary" />, label: 'Bars' },
    { icon: <RestaurantIcon fontSize="large" color="primary" />, label: 'Restaurants' },
    { icon: <LocalActivityIcon fontSize="large" color="primary" />, label: 'Experiences' },
    { icon: <DirectionsCarIcon fontSize="large" color="primary" />, label: 'Transfers' },
    { icon: <CameraAltIcon fontSize="large" color="primary" />, label: 'Photoshoots' },
    { icon: <ShoppingCartIcon fontSize="large" color="primary" />, label: 'and more...' },
  ];

  const steps = [
    { icon: <ShoppingCartCheckoutIcon fontSize="large" color="primary" />, title: "Purchase Online", description: `Visit our website to select and purchase your Paphos Pass. Complete the checkout process, and you'll receive your pass instantly via email.` },
    { icon: <CreditCardIcon fontSize="large" color="primary" />, title: "Present Your Pass", description: `Show your Paphos Pass to the staff at any participating venue or attraction. They will verify your pass with your ID.` },
    { icon: <LocalOfferIcon fontSize="large" color="primary" />, title: "Enjoy Instant Discounts", description: `Once verified, enjoy instant discounts on your purchases. Save up to 1500€ during your holiday in Paphos.` },
  ];

  return (
    <Box sx={{ my: 8, textAlign: 'center' }}>
      <Container>
        <Typography variant="h4" gutterBottom id="about">
          What is Paphos Pass?
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ mb: 4 }}>
          Discover Paphos like never before! Paphos Pass is your ultimate companion for exploring the beautiful city of Paphos, offering discounts on:
        </Typography>
        <Grid container spacing={3} justifyContent="center" sx={{ mb: 6 }}>
          {features.map((feature, index) => (
            <Grid item xs={6} sm={4} md={2} key={index}>
              <Fade in={true} timeout={500 + index * 100}>
                <FeatureCard>
                  {feature.icon}
                  <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 2 }}>{feature.label}</Typography>
                </FeatureCard>
              </Fade>
            </Grid>
          ))}
        </Grid>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Effortlessly explore, save, and make the most of your stay with exclusive deals and insider tips.
        </Typography>

        <Typography variant="h4" sx={{ mt: 8, mb: 4 }} id="how-it-works">
          How It Works
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {steps.map((step, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Fade in={true} timeout={500 + index * 200}>
                <StepCard>
                  {step.icon}
                  <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                    {step.title}
                  </Typography>
                  <Typography variant="body2">
                    {step.description}
                  </Typography>
                </StepCard>
              </Fade>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default AboutPaphosPass;
