import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51PLoHgJl3ggxuLgNuDfogWLyOf8pa57T3KJFjPfHY3Hd9nBACV4XLIhy2L1B8zl3IEaBhhrxtKiv0mCgjXBdGxSQ00SwWjxy04');

const Checkout = () => {
  const { state } = useLocation();
  const { names, emails, startDate, priceId, passType } = state;

  useEffect(() => {
    const initiateCheckout = async () => {
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        lineItems: [{ price: priceId, quantity: names.length }],
        mode: 'payment',
        successUrl: 'https://www.paphospass.com/success?session_id={CHECKOUT_SESSION_ID}',
        cancelUrl: 'https://www.paphospass.com/fail',
        customerEmail: emails[0],  // Use the first email as the customer email
        clientReferenceId: userId, // Pass the Firestore document ID to Stripe
      });

      if (error) {
        console.error('Error:', error);
      }
    };

    initiateCheckout();
  }, [names, emails, startDate, priceId, passType]);

  return null;
};

export default Checkout;

