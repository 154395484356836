import React from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, Avatar } from '@mui/material';

const testimonials = [
  {
    name: "John Mitchell",
    avatar: "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg",
    text: "The Paphos Pass made our trip so much more affordable and enjoyable. Highly recommended!"
  },
  {
    name: "Emma Thompson",
    avatar: "https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg",
    text: "We saved so much money and got to experience more than we expected. Great value!"
  },
  {
    name: "David Wright",
    avatar: "https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg",
    text: "Fantastic discounts and easy to use. The Paphos Pass made our holiday stress-free."
  },
  {
    name: "Sarah Johnson",
    avatar: "https://images.pexels.com/photos/1036623/pexels-photo-1036623.jpeg",
    text: "An absolute must-have for anyone visiting Paphos. The savings were incredible."
  },
  {
    name: "Michael Brown",
    avatar: "https://images.pexels.com/photos/937481/pexels-photo-937481.jpeg",
    text: "The best investment we made for our holiday. Highly effective and easy to use."
  },
];

const Testimonials = () => {
  return (
    <Box sx={{ my: 8, textAlign: 'center' }}>
      <Container>
        <Typography variant="h4" gutterBottom>
          What Our Customers Say
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {testimonials.map((testimonial, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card>
                <CardContent>
                  <Avatar src={testimonial.avatar} alt={testimonial.name} sx={{ width: 56, height: 56, mx: 'auto', mb: 2 }} />
                  <Typography variant="body1" gutterBottom>
                    {testimonial.text}
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary">
                    - {testimonial.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Testimonials;

