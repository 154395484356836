import React, { useEffect } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { db } from '../firebaseConfig'; // Ensure you have initialized Firebase
import { doc, updateDoc } from 'firebase/firestore'; // Import Firestore methods
import { Container, Typography, Box, Paper, Button, useMediaQuery } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTheme } from '@mui/material/styles';

const Fail = () => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sessionId = params.get('session_id');
    const userId = localStorage.getItem('userId');

    if (sessionId && userId) {
      const updatePaymentStatus = async () => {
        try {
          // Update Firestore with payment status
          const userRef = doc(db, 'users', userId);
          await updateDoc(userRef, {
            status: 'payment_failed',
            stripeSessionId: sessionId,
          });
        } catch (error) {
          console.error('Error updating Firestore:', error);
        }
      };

      updatePaymentStatus();
    }
  }, [location.search]);

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Paper elevation={3} sx={{ p: isMobile ? 2 : 4, textAlign: 'center', maxWidth: 600, width: '100%' }}>
        <CancelIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
        <Typography variant="h4" gutterBottom>
          Payment Failed
        </Typography>
        <Typography variant="body1" gutterBottom>
          Unfortunately, your payment was not successful.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please try again or contact support if you continue to experience issues.
        </Typography>
        <Button variant="contained" color="primary" component={RouterLink} to="/" sx={{ mt: 2 }}>
          Go to Homepage
        </Button>
      </Paper>
    </Container>
  );
};

export default Fail;

