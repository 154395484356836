import React from 'react';
import { Container, Typography, Box, Grid, IconButton } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  const location = useLocation(); // Get the current location

  return (
    <Box sx={{ backgroundColor: '#f8f8f8', py: 4 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Typography variant="body2">
              <RouterLink to="/" style={{ display: 'block', color: 'inherit', textDecoration: 'none', cursor: 'pointer' }}>Home</RouterLink>
              {location.pathname === '/' && ( // Conditionally render the other links
                <>
                  <ScrollLink to="about" smooth={true} duration={500} offset={-70} style={{ display: 'block', color: 'inherit', textDecoration: 'none', cursor: 'pointer' }}>About PaphosPass</ScrollLink>
                  <ScrollLink to="how-it-works" smooth={true} duration={500} offset={-70} style={{ display: 'block', color: 'inherit', textDecoration: 'none', cursor: 'pointer' }}>How it Works</ScrollLink>
                  <ScrollLink to="faq" smooth={true} duration={500} offset={-70} style={{ display: 'block', color: 'inherit', textDecoration: 'none', cursor: 'pointer' }}>FAQ</ScrollLink>
                  <ScrollLink to="passes" smooth={true} duration={500} offset={-70} style={{ display: 'block', color: 'inherit', textDecoration: 'none', cursor: 'pointer' }}>Passes</ScrollLink>
                  <ScrollLink to="contact" smooth={true} duration={500} offset={-70} style={{ display: 'block', color: 'inherit', textDecoration: 'none', cursor: 'pointer' }}>Contact</ScrollLink>
                </>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2">
              Email: <a href="mailto:info@paphospass.com" style={{ color: 'inherit' }}>info@paphospass.com</a>
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Address: 123 Paphos Street, Paphos, Cyprus
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Phone: +357 123 456 789
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Follow Us
            </Typography>
            <Box>
              <IconButton href="https://facebook.com" target="_blank" rel="noopener" sx={{ color: 'inherit' }}>
                <FacebookIcon />
              </IconButton>
              <IconButton href="https://twitter.com" target="_blank" rel="noopener" sx={{ color: 'inherit' }}>
                <TwitterIcon />
              </IconButton>
              <IconButton href="https://instagram.com" target="_blank" rel="noopener" sx={{ color: 'inherit' }}>
                <InstagramIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="body2">
            &copy; {new Date().getFullYear()} PaphosPass. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;

