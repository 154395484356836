import React from 'react';
import { Container, Typography, Button, Box, useTheme, useMediaQuery } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import NewsletterSubscription from '../components/NewsletterSubscription';
import Header from '../components/Header';
import Footer from '../components/Footer';
import heroImage from '../assets/hero.jpg';
import AboutPaphosPass from '../components/AboutPaphosPass';
import OurPasses from '../components/OurPasses';
import FAQ from '../components/FAQ';
import Testimonials from '../components/Testimonials';

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Header />
      <Box
        id="home"
        sx={{
          backgroundImage: `url(${heroImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          textAlign: 'center',
          p: isMobile ? 2 : 4,
          paddingTop: isMobile ? '80px' : '0',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            padding: isMobile ? '15px' : '20px',
            borderRadius: '10px',
            maxWidth: '90%',
          }}
        >
          <Typography 
            variant={isMobile ? "h4" : "h2"} 
            gutterBottom 
            color="primary"
            sx={{ 
              fontSize: isMobile ? '1.75rem' : '3.75rem',
              lineHeight: 1.2,
            }}
          >
            Discover Paphos with Exclusive Discounts
          </Typography>
          <Typography 
            variant={isMobile ? "body1" : "h6"} 
            gutterBottom 
            color="primary"
            sx={{ 
              fontSize: isMobile ? '1rem' : '1.25rem',
            }}
          >
            We made it simple - save up to 1500€ on your holidays.
          </Typography>
          <ScrollLink to="passes" smooth={true} duration={500} offset={-70}>
            <Button 
              variant="contained" 
              color="primary" 
              sx={{ mt: 2, fontSize: isMobile ? '0.875rem' : '1rem' }}
            >
              Buy Now
            </Button>
          </ScrollLink>
        </Box>
      </Box>
      <Container>
        <Box id="about">
          <AboutPaphosPass />
        </Box>
        <Box id="passes">
          <OurPasses />
        </Box>
        <Box id="testimonials">
          <Testimonials />
        </Box>
        <Box id="faq">
          <FAQ />
        </Box>
        <Box id="contact" sx={{ my: 4 }}>
          <NewsletterSubscription />
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default Home;

