import React, { useState } from 'react';
import { Container, Typography, Grid, Box, Card, CardContent, Button } from '@mui/material';
import { styled } from '@mui/system';   
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router-dom';

import pass1 from '../assets/pass1.png';
import pass2 from '../assets/pass2.png';
import pass3 from '../assets/pass3.png';

const StyledCard = styled(Card)({
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0px 5px 15px rgba(0,0,0,0.2)',
  },
});

const OurPasses = () => {
  const navigate = useNavigate();
  const passes = [
    {
        title: '3 Days Pass',
        price: '29€',
        image: pass1,
        shortDescription: 'Exclusive discounts at top bars, restaurants, and attractions for three full days.',
        details: 'Valid for 2 adults and their children up to 12 years.',
        priceId: 'price_1PUERCJl3ggxuLgNRUzV6c2n',
    },
    {
        title: '7 Days Pass',
        price: '39€',
        image: pass2,
        shortDescription: 'Unbeatable deals across Paphos for seven days, covering culinary scene, experiences, and attractions.',
        details: 'Valid for 2 adults and their children up to 12 years.',
        priceId: 'price_1PUERWJl3ggxuLgN5eDc68rb',
    },
    {
        title: '10 Days Pass',
        price: '49€',
        image: pass3,
        shortDescription: 'Fantastic discounts at the best local spots for ten days, ideal for extended stays.',
        details: 'Valid for 2 adults and their children up to 12 years.',
        priceId: 'price_1PUERnJl3ggxuLgN2cii0Ibj',
    },
  ];

  const handleBuyNow = (pass) => {
    navigate('/userinfo', { state: { pass } });
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom sx={{ mt: 6, mb: 4, textAlign: 'center' }} id="passes">
        Our Passes
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {passes.map((pass, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <StyledCard sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Box sx={{ position: 'relative', height: 200 }}>
                <Box
                  component="img"
                  sx={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                  }}
                  alt={pass.title}
                  src={pass.image}
                  loading="lazy"
                />
                {index === 1 && (
                  <Chip
                    label="Best Value"
                    color="secondary"
                    sx={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                    }}
                  />
                )}
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
                    color: '#fff',
                    textAlign: 'center',
                    p: 2,
                  }}
                >
                  <Typography variant="h6" sx={{ color: '#fff' }}>{pass.title}</Typography>
                </Box>
              </Box>
              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h5" color="primary" sx={{ fontWeight: 'bold', mb: 2 }}>{pass.price}</Typography>
                <Typography variant="body2" gutterBottom>{pass.shortDescription}</Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mt: 'auto', mb: 2 }}>{pass.details}</Typography>
                <Button 
                  variant="contained" 
                  color="primary" 
                  fullWidth
                  onClick={() => handleBuyNow(pass)}
                >
                  Buy Now
                </Button>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default OurPasses;

