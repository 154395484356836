import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Button, Box, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../assets/logo.png';
import { useLocation } from 'react-router-dom';


// const Header = () => {
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

//   useEffect(() => {
//     const handleScroll = () => {
//       setIsScrolled(window.scrollY > 0);
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setIsDrawerOpen(open);
//   };

//   const navItems = [
//     { id: 'home', label: 'Home' },
//     { id: 'about', label: 'About PaphosPass' },
//     { id: 'how-it-works', label: 'How it Works' },
//     { id: 'faq', label: 'FAQ' },
//     { id: 'passes', label: 'Passes' },
//     { id: 'contact', label: 'Contact' }
//   ];

//   const drawerContent = (
//     <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
//       <List>
//         {navItems.map((item) => (
//           <ListItem button key={item.id}>
//             <ScrollLink to={item.id} smooth={true} duration={500} offset={-70} style={{ textDecoration: 'none', color: 'inherit' }}>
//               <ListItemText primary={item.label} />
//             </ScrollLink>
//           </ListItem>
//         ))}
//       </List>
//     </Box>
//   );

//   const navItemColor = isScrolled ? '#000' : '#3057f3';

//   return (
//     <>
//       <AppBar 
//         position="fixed" 
//         sx={{
//           backgroundColor: isScrolled ? 'rgba(255, 255, 255, 0.9)' : 'transparent',
//           transition: 'background-color 0.3s',
//           boxShadow: 'none',
//         }}
//       >
//         <Toolbar>
//           <Box component={RouterLink} to="/" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
//             <img src={Logo} alt="Paphos Pass" style={{ height: 50, marginRight: 20 }} />
//           </Box>
//           <Box sx={{ flexGrow: 1 }} />
//           <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
//             {navItems.map((item) => (
//               <ScrollLink key={item.id} to={item.id} smooth={true} duration={500} offset={-70}>
//                 <Button color="inherit" sx={{ color: navItemColor }}>
//                   {item.label}
//                 </Button>
//               </ScrollLink>
//             ))}
//           </Box>
//           <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
//             <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
//               <MenuIcon sx={{ color: navItemColor }} />
//             </IconButton>
//             <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
//               {drawerContent}
//             </Drawer>
//           </Box>
//         </Toolbar>
//       </AppBar>
//       <Toolbar />
//     </>
//   );
// };

// export default Header;

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation(); // Get the current location

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const navItems = [
    { id: 'home', label: 'Home' },
    { id: 'about', label: 'About PaphosPass' },
    { id: 'how-it-works', label: 'How it Works' },
    { id: 'faq', label: 'FAQ' },
    { id: 'passes', label: 'Passes' },
    { id: 'contact', label: 'Contact' }
  ];

  const drawerContent = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        {navItems.map((item) => (
          <ListItem button key={item.id}>
            <ScrollLink to={item.id} smooth={true} duration={500} offset={-70} style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItemText primary={item.label} />
            </ScrollLink>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const navItemColor = isScrolled ? '#000' : '#3057f3';

  return (
    <>
      <AppBar 
        position="fixed" 
        sx={{
          backgroundColor: isScrolled ? 'rgba(255, 255, 255, 0.9)' : 'transparent',
          transition: 'background-color 0.3s',
          boxShadow: 'none',
        }}
      >
        <Toolbar>
          <Box component={RouterLink} to="/" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
            <img src={Logo} alt="Paphos Pass" style={{ height: 50, marginRight: 20 }} />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          {location.pathname === '/' && ( // Conditionally render navigation items
            <>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                {navItems.map((item) => (
                  <ScrollLink key={item.id} to={item.id} smooth={true} duration={500} offset={-70}>
                    <Button color="inherit" sx={{ color: navItemColor }}>
                      {item.label}
                    </Button>
                  </ScrollLink>
                ))}
              </Box>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                  <MenuIcon sx={{ color: navItemColor }} />
                </IconButton>
                <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                  {drawerContent}
                </Drawer>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};

export default Header;