import React, { useState } from 'react';
import { Container, Typography, Box, Card, CardContent, IconButton, TextField, Button, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate, useLocation } from 'react-router-dom';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Header from '../components/Header'; // Assuming you have a Header component
import Footer from '../components/Footer'; // Assuming you have a Footer component

import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_test_51PLoHgJl3ggxuLgNuDfogWLyOf8pa57T3KJFjPfHY3Hd9nBACV4XLIhy2L1B8zl3IEaBhhrxtKiv0mCgjXBdGxSQ00SwWjxy04');

import { db } from '../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore'; // Import Firestore methods


const GradientContainer = styled(Container)({
  background: 'linear-gradient(to right, #e0e0e0, #f8f9fa)',
  paddingTop: '2rem',
  paddingBottom: '4rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});
const commonMaxWidth = 700; // Set a common maxWidth for both the card and the form container


const StyledCard = styled(Card)({
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0px 5px 15px rgba(0,0,0,0.2)',
    },
    width: '100%',
    maxWidth: commonMaxWidth,
    marginBottom: '2rem',
  });
  
  const FormContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: commonMaxWidth,
    width: '100%',
  });

const StyledPaper = styled(Paper)({
  padding: '1.5rem',
  marginBottom: '1rem',
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  width: '100%',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
});

const Title = styled(Typography)({
  marginBottom: '2rem',
  fontSize: '2rem',
  fontWeight: 'bold',
  color: '#333',
});

const SubTitle = styled(Typography)({
  marginBottom: '1rem',
  fontSize: '1.25rem',
  fontWeight: 'bold',
  color: '#555',
});

const UserInfo = () => {
  const { state } = useLocation();
  const { pass } = state;
  const [numPasses, setNumPasses] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [passHolders, setPassHolders] = useState([{ name: '', email: '' }]);
  const navigate = useNavigate();

  const handleAddPass = () => {
    setNumPasses(numPasses + 1);
    setPassHolders([...passHolders, { name: '', email: '' }]);
  };

  const handleRemovePass = () => {
    if (numPasses > 1) {
      setNumPasses(numPasses - 1);
      setPassHolders(passHolders.slice(0, -1));
    }
  };

  const handlePassHolderChange = (index, field, value) => {
    const newPassHolders = [...passHolders];
    newPassHolders[index][field] = value;
    setPassHolders(newPassHolders);
  };

  const handleNext = async () => {
    console.log("Pass Holders: ", passHolders); 
    console.log("Start Date: ", startDate);
    console.log("Pass: ", pass);
    
    if (passHolders.some(holder => !holder.name || !holder.email) || !startDate) {
        alert('Please fill in all fields');
        return;
      }
  
      const pricePerPass = parseFloat(pass.price.replace('€', ''));
      const totalPrice = pricePerPass * numPasses;
      const daysValid = pass.title.split(' ')[0];
  
      try {
        // Save user data to Firestore
        const userRef = await addDoc(collection(db, 'users'), {
          passHolders,
          startDate,
          priceId: pass.priceId,
          pricePerPass,
          totalPrice,
          daysValid,
          createdAt: new Date(),
          status: 'new',
        });
  
        const userId = userRef.id;
  
        // Save the userId in localStorage
        console.log("User ID: ", userId);
        localStorage.setItem('userId', userId);
  
        const stripe = await stripePromise;
  
        const { error } = await stripe.redirectToCheckout({
            lineItems: [{ price: pass.priceId, quantity: numPasses }],
            mode: 'payment',
            successUrl: 'https://www.paphospass.com/success?session_id={CHECKOUT_SESSION_ID}',
            cancelUrl: 'https://www.paphospass.com/cancel',
            customerEmail: passHolders[0].email,
            clientReferenceId: userId, // Pass the Firestore document ID to Stripe
          });
    
          if (error) {
            console.error('Error:', error);
          }
        } catch (e) {
          console.error('Error adding document: ', e);
        }
      };
  
  


  return (
    <>
      <Header />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <GradientContainer>
          <Title variant="h4" sx={{ textAlign: 'center' }}>
            Enter Your Details
          </Title>

          <StyledCard>
            <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box
                component="img"
                sx={{
                    height: 250,
                    width: 250,
                    objectFit: 'cover',
                    marginRight: 2,
                }}
                alt={pass.title}
                src={pass.image}
                loading="lazy"
                />
                <Box>
                <Typography variant="h5" gutterBottom>{pass.title}</Typography>
                <Typography variant="body1" gutterBottom>{pass.shortDescription}</Typography>
                <Typography variant="body2" color="textSecondary">{pass.details}</Typography>
                <Typography variant="h6" color="primary" sx={{ mt: 2 }}>{pass.price}</Typography>
                </Box>
            </CardContent>
            </StyledCard>
            <FormContainer>
            <SubTitle variant="h6">Number of Passes</SubTitle>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <IconButton onClick={handleRemovePass} disabled={numPasses <= 1}>
                <RemoveIcon />
                </IconButton>
                <Typography variant="h6" sx={{ mx: 2 }}>{numPasses}</Typography>
                <IconButton onClick={handleAddPass}>
                <AddIcon />
                </IconButton>
            </Box>
            {passHolders.map((holder, index) => (
                <StyledPaper key={index}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <PersonIcon sx={{ mr: 1 }} />
                    <TextField
                    label={`Pass Holder ${index + 1} Name`}
                    value={holder.name}
                    onChange={(e) => handlePassHolderChange(index, 'name', e.target.value)}
                    margin="normal"
                    fullWidth
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <EmailIcon sx={{ mr: 1 }} />
                    <TextField
                    label={`Pass Holder ${index + 1} Email`}
                    value={holder.email}
                    onChange={(e) => handlePassHolderChange(index, 'email', e.target.value)}
                    margin="normal"
                    fullWidth
                    />
                </Box>
                </StyledPaper>
            ))}
            <StyledPaper sx={{ display: 'flex', alignItems: 'center', mb: 2, width: '100%' }}>
                <CalendarTodayIcon sx={{ mr: 1 }} />
                <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newDate) => setStartDate(newDate)}
                renderInput={(params) => <TextField {...params} fullWidth />}
                />
            </StyledPaper>
            <Button variant="contained" color="primary" onClick={handleNext} sx={{ mt: 3 }}>
                Next
            </Button>
            </FormContainer>

          
          
          


          
         
        </GradientContainer>
      </LocalizationProvider>
      <Footer />
    </>
  );
};

export default UserInfo;

