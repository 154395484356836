import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  const faqs = [
    {
      question: "What is the Paphos Pass?",
      answer: "The Paphos Pass is a discount card that offers exclusive discounts at top bars, restaurants, attractions, and more in Paphos. It allows you to save up to 1500€ on your holiday expenses."
    },
    {
      question: "How do I purchase the Paphos Pass?",
      answer: "You can purchase the Paphos Pass directly from our website. Simply choose the pass that best suits your needs, complete the checkout process, and you will receive your pass instantly via email."
    },
    {
      question: "How do I use the Paphos Pass?",
      answer: "Show your Paphos Pass to the staff at any participating venue or attraction. They will verify your pass with your ID, and you can then enjoy instant discounts on your purchases."
    },
    {
      question: "Is the Paphos Pass valid for multiple people?",
      answer: "Yes, the Paphos Pass is valid for 2 adults and their children up to 12 years old."
    },
    {
      question: "How long is the Paphos Pass valid for?",
      answer: "The validity of the Paphos Pass depends on the type of pass you purchase. We offer 3-day, 7-day, and 10-day passes."
    },
    {
      question: "Can I get a refund if I change my mind?",
      answer: "Unfortunately, we do not offer refunds once the pass has been purchased. Please make sure to choose the pass that best suits your needs."
    },
    {
      question: "What should I do if I encounter an issue with my pass?",
      answer: "If you encounter any issues with your pass, please contact our customer support team via email or phone, and we will assist you as soon as possible."
    },
  ];

  return (
    <Container sx={{ my: 8 }}>
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
        Frequently Asked Questions
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography variant="h6">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default FAQ;


