import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { db } from '../firebaseConfig'; // Ensure you have initialized Firebase
import { doc, updateDoc, getDoc } from 'firebase/firestore'; // Import Firestore methods
import { Container, Typography, Box, Paper, CircularProgress, useMediaQuery } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTheme } from '@mui/material/styles';

const Success = () => {
  const location = useLocation();
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const isSendingPasses = useRef(false); // Ref to prevent multiple submissions

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sessionId = params.get('session_id');
    const userId = localStorage.getItem('userId');

    if (sessionId && userId && !isSendingPasses.current) { // Check the ref
      const fetchSession = async () => {
        try {
          setLoading(true);
          const response = await axios.get(`https://paphos-pass-be-6k7awnsasq-ew.a.run.app//api/get-session?sessionId=${sessionId}`);
          setSession(response.data);
          console.log("Session data: ", response.data);

          // Update Firestore with Stripe session ID and payment status
          const userRef = doc(db, 'users', userId);
          await updateDoc(userRef, {
            stripeSessionId: sessionId,
            status: 'paid',
            stripePaymentId: response.data.payment_intent,
          });

          // Fetch user data
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            console.log("User data: ", userData);

            // Send passes only once
            if (!isSendingPasses.current) {
              isSendingPasses.current = true; // Set the ref to true
              await axios.post('https://paphos-pass-be-6k7awnsasq-ew.a.run.app//api/send-passes', {
                userId,
                passHolders: userData.passHolders,
                passType: userData.daysValid,
                startDate: userData.startDate,
              });
              isSendingPasses.current = false; // Reset the ref
            }
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching session or updating Firestore:', error);
          setLoading(false);
        }
      };

      fetchSession();
    }
  }, [location.search]); // Remove isSendingPasses from dependency array

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <div style={{ maxWidth: '600px', padding: '2rem', border: '1px solid #ddd', borderRadius: '8px', textAlign: 'center', backgroundColor: '#fff' }}>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            <CheckCircleOutlineIcon style={{ color: '#4CAF50', fontSize: '3rem', marginBottom: '1rem' }} />
            <h1 style={{ color: '#4CAF50' }}>Payment Successful!</h1>
            <p>Congratulations on getting your Paphos Pass!</p>
            {session && (
              <>
                <p>Your payment was successful and your transaction ID is:</p>
                <p><strong>{session.id}</strong></p>
                <p>Amount Paid: <strong>{session.amount_total / 100} {session.currency.toUpperCase()}</strong></p>
              </>
            )}
            <p>You will receive your passes shortly in your email.</p>
            <p>Enjoy your stay in Paphos!</p>
          </>
        )}
      </div>
    </div>
  );
};

export default Success;

